.mbc-search{
    width:100%;
    background: none;
  }
  
  .mbc-search ion-searchbar{
    --background: var(--mbc-main-background);
    --icon-color: var(--mbc-search-placeholder-color);
    --clear-button-color: var(--mbc-search-button-color);
    
    --border-radius: 12px;
    --opacity: 1;
    --box-shadow: 0px 0px 0px 1px var(--mbc-border-color);
    width: 100%;
    text-align: left;
    padding: 0px;
    color:var(--mbc-search-placeholder-color);
  }