/** Ionic CSS Variables **/
:root {

  /** MBC specific variables **/

  --mbc-primary: #0191FF;
  --mbc-secondary: #F97949;
  --mbc-tertiary:#F7DF86;
  --mbc-green: #14C849;
  --mbc-border-color:#EAEBEC;
  --mbc-default-node-color: #D3D3D3;
  --mbc-scrollable-item-gap: 2px;
  --mbc-checkbox-size: 16px;
  --mbc-menu-button-color: #A8A3A1;
  --mbc-danger: #EA1212;

  /* MBC Message Card variables*/
  --mbc-message-card-background-color: #FFFFFF;
  --mbc-message-card-box-shadow: 0px 2px 48px #0000000A;
  --mbc-message-card-content-info-color: #202020;

  /* MBC Message Modal variables*/
  --mbc-message-modal-right-background: #eaebec;
  --mbc-message-modal-right-border: 1px solid var(--mbc-border-color);
  --mbc-message-modal-right-border-radius: 12px;

  /* MBM Viewer - Door cards variables*/
  --mbm-door-card-default-color: #CC29E6;

  /** Grid Checkbox filter variables **/
  --documents-grid-checkbox-filter-border: 1px solid #A8A3A1;

  /** Document Card variables **/
  --document-card-download-icon-color: #0191FF;
  --document-card-delete-icon-color: #FF0000;
  --document-card-update-icon-color: #0191FF;
  --document-card-file-name-color: #282625;
  --document-card-uploaded-date-color: #A8A3A1;

  /** MBC Document page variables **/
  --mbc-document-title-color: #282625;
  --mbc-document-scrollable-thumb-background-color: #e6e5e5;
  --mbc-document-scrollable-thumb-border-color: var(--mbc-border-color);
  --mbc-document-scrollable-thumb-hover-color: #d7d6d6;
  --mbc-document-card-selection-background-color: #f0f8ff;

  /** MBC scrollable variables **/
  --mbc-scrollable-thumb-background-color: #e6e5e5;
  --mbc-scrollable-thumb-border-color: var(--mbc-border-color);
  --mbc-scrollable-thumb-hover-color: #d7d6d6;

  /**  MBC Back icon variables **/
  --mbc-back-icon-color: #A8A3A1;
  --mbc-back-icon-hover-color: #525151;

  /** Static Mobile Friendly Page variables **/
  --static-mobile-friendly-icon-color: var(--mbc-primary);

  /** MBC Confirmation vairables **/
  --mbc-confirmation-message-color: #A8A3A1;

    /** MBC Error Handling vairables **/
    --mbc-error-message-color: #A8A3A1;

  /** MBC font variables **/
  --mbc-font-type-default: "Roboto";
  --mbc-font-type-medium: "Roboto-medium";
  --mbc-font-type-light: "Roboto-light";
  --mbc-font-style-default: normal;
  --mbc-font-variant-default: normal;
  --mbc-font-weight-default: normal;

  /** MBC layout variables **/
  --mbc-main-background-color: var(--ion-color-secondary-contrast);
  --mbc-main-background: var(--mbc-main-background-color) 0% 0% no-repeat padding-box;
  --mbc-secondary-background-color: #F2F2F2;

  /** MBC text variables **/

  --mbc-text-color-default: #817e7e;
  --mbc-text-color-light: #A8A3A1;
  --mbc-text-color-important: #FF0000;
  --mbc-text-color-title: #363430;
  --mbc-text-shadow-default: #747474;
  --mbc-text-underline-border: 1px solid #F0F0F0;

  /** MBC tile variables **/

  --mbc-tile-border-radius: 12px;
  --mbc-tile-border-shadow: 0px 2px 48px #0000000A;
  --mbc-tile-header-color: #585858;
  --mbc-tile-header-info-color: #B2B1B1;
  --mbc-tile-border-shadow: var(--mbc-card-border-shadow);
  --mbc-tile-scroll-background-color: #9898980a;
  --mbc-tile-scroll-thumb-background-color: #e6e5e5;
  --mbc-tile-scroll-thumb-hover-color: #d7d6d6;
  --mbc-tile-scroll-thumb-border-color: var(--mbc-border-color);

  /** MBC door card variables **/
  --mbc-door-card-header-color: #000000;
  --mbc-door-card-detail-color: var(--mbc-text-color-light);
  --mbc-door-card-selection-background-color: #f0f8ff;
  --mbc-door-card-selection-border-color: #c3c2c2;

  /** MBP door card variables **/
  --mbp-door-card-header-color: #000000;
  --mbp-door-card-detail-color: var(--mbc-text-color-light);
  --mbp-door-card-selection-background-color: #f0f8ff;
  --mbp-door-card-selection-border-color: #c3c2c2;
  --mbp-door-card-left-box-background: var(--mbc-default-node-color);
  --mbp-door-card-connected-icon-svg-fill: var(--mbc-default-node-color);
  --mbp-door-card-wrapper-hover-border: 1px dotted #c3c2c2;

  /** MBC door props card variables **/
  --mbc-door-prop-card-header: var(--mbc-text-color-light);
  --mbc-door-prop-card-content: #000000;
  --mbc-door-prop-card-boarder: #EAEBEC;

  /** MBC door props card variables **/
  --door-props-card-header: var(--mbc-text-color-light);
  --door-props-card-content: #000000;
  --door-props-card-boarder: #EAEBEC;

  // MBP door props card variables
  --mbp-door-props-card-boarder: #EAEBEC;
  --mbp-door-props-card-header: var(--mbc-text-color-light);
  --mbp-door-props-card-content: #000000;

  /// counter tile variables

  --mbc-counter-tile-header-text-color: #585858;
  --mbc-conter-tile-background-color: #FFFFFF;
  --mbc-conter-tile-disabled-color: var(--mbc-button-primary-disabled-color)

  /** MBC notification variables **/
  --mbc-notification-font: normal normal normal 14px Roboto-medium;

  /** MBC card variables **/
  --mbc-card-header-text-color:#585858;
  --mbc-card-header-text-color-light:#B2B1B1;
  --mbc-card-info-text-color: #bfbcba;
  --mbc-card-label-light:#979797;
  --mbc-card-progress-background:#D3CECE;
  --mbc-card-progress-inner:#f9fafa;
  --mbc-card-background: #FFFFFF;
  --mbc-card-border-shadow: 0px 2px 48px #0000000A;
  --mbc-card-border-color:var(--mbc-border-color);
  --mbc-card-indicator-color: var(--ion-color-primary);
  --mbc-card-selector-color: var(--ion-color-primary);
  --mbc-card-selected-color:#e6f4ff;
  --mbc-door-card-background: var(--mbc-card-background);
  --mbc-door-card-left-box-background: var(--mbc-default-node-color);
  --mbc-door-card-connected-icon-svg-fill: var(--mbc-default-node-color);

  /** MBC detailing card variables **/
  --mbc-detail-code-card-selection-border-color: var(--mbc-door-card-selection-border-color);
  --mbc-detail-code-card-selection-background-color: var(--mbc-door-card-selection-background-color);
  --mbc-detail-code-card-left-box-background: var(--mbc-door-card-left-box-background);
  --mbc-detail-code-card-header-color: var(--mbc-door-card-header-color);
  --mbc-detail-code-card-detail-color: var(--mbc-door-card-detail-color);

  /**MBC search bar variables **/
  --mbc-search-placeholder-color: #c2c2c2;
  --mbc-search-button-color: #5c5a5a;

  /** MBC Loader specific variables **/
  --mbc-loading-screen-spinner-color: var(--ion-color-primary-contrast);

  /** MBC floor plan card variables **/
  --floor-plan-card-progress-color: #007fe0;
  --floor-plan-card-progress-background: var(--mbc-primary) 0% 0% no-repeat padding-box;
  --floor-plan-file-card-info-color: #bfbcba;

  /** MBC floor plan pdf card variables **/
  --floor-plan-pdf-card-info-color: #bfbcba;

  /** MBC confirm file upload card variables **/
  --confirm-file-upload-card-old-name-color: #bcbcbc;

  /** MBC button variables **/
  --mbc-viewer-right-menu-button-color: #A8A3A1;
  --mbc-button-primary-color: var(--ion-color-primary);
  --mbc-button-secondary-color: var(--ion-color-secondary);
  --mbc-button-primary-hover-color: #ffff;
  --mbc-button-primary-activated-color: var(--mbc-button-primary-color);
  --mbc-button-primary-focused-color: #ffff;
  --mbc-button-primary-border-color: var(--mbc-button-primary-color);
  --mbc-button-primary-ripple-color: var(--mbc-button-primary-color);
  --mbc-button-primary-text-color: var(--ion-color-primary-contrast);
  --mbc-button-primary-disabled-color: var(--ion-color-medium-shade);

  /** MBC secondary button variables **/

  --mbc-button-secondary-background: var(--ion-color-primary-contrast) 0% 0% no-repeat padding-box;
  --mbc-button-secondary-background-hover: #A8A3A1 0% 0% no-repeat padding-box;
  --mbc-button-secondary-text-color: var(--ion-color-primary);
  --mbc-button-secondary-box-shadow: 0px 4px 5px #0000000F;
  --mbc-button-secondary-disabled-background: var(--mbc-secondary-background-color) 0% 0% no-repeat padding-box;
  --mbc-button-secondary-disabled-text-color: #b8b8b8;

  /** MBC input Variables **/
  --mbc-input-background: var(--ion-color-secondary-contrast);
  --mbc-input-border: 1px solid var(--mbc-border-color);
  --mbc-input-placeholder-color: #c2c2c2;

  /** Floor menu bar variables **/
  --floor-menu-bar-box-shadow: 0px 4px 5px #0000000F;
  --floor-menu-bar-selected-background-color: var(--ion-color-primary-contrast);
  --floor-menu-bar-background: var(--mbc-secondary-background-color);
  --floor-menu-bar-unselected-text-color: #b8b8b8;
  --floor-menu-bar-selected-text-color: var(--ion-color-primary);

  /** Map Colors variables **/
  --map-colors-name-color: #282625;
  --map-colors-tile-background: var(--ion-color-secondary-contrast);
  --map-colors-tile-box-shadow: 0px 2px 48px #0000000A;
  --map-colors-tile-border: 1px solid var(--mbc-border-color);
  --map-colors-tile-border-radius: 12px;

  /** Map Colors Card variables **/
  --map-color-card-background-hover-color:#f3f3f3;
  --map-color-card-left-box-background: var(--mbc-default-node-color);
  --map-color-card-background: var(--ion-color-secondary-contrast);
  --map-color-card-box-shadow: 0px 2px 48px #0000000A;
  --map-color-card-border: 1px solid var(--mbc-border-color);
  --map-color-card-border-radius: 13px 15px 15px 13px;

  /** MBC Colors Card variables **/
  --mbc-color-card-background-hover-color:#f3f3f3;
  --mbc-color-card-icon-color : var(--mbc-default-node-color);
  --mbc-color-card-background: var(--ion-color-secondary-contrast);
  --mbc-color-card-box-shadow: 0px 2px 48px #0000000A;
  --mbc-color-card-border: 1px solid var(--mbc-border-color);
  --mbc-color-card-border-radius: 12px;

/** MBP Map Colors variables **/
  --mbp-map-colors-tile-background: var(--ion-color-secondary-contrast);
  --mbp-map-colors-tile-box-shadow: 0px 2px 48px #0000000A;
  --mbp-map-colors-tile-border: 1px solid var(--mbc-border-color);
  --mbp-map-colors-tile-border-radius: 12px;

  /** MBP Map Color Card variables **/
  --mbp-map-color-card-name-color: #282625;
  --mbp-map-color-card-background-hover-color: #f3f3f3;
  --mbp-map-color-card-left-box-background: var(--mbc-default-node-color);
  --mbp-map-color-card-background: var(--ion-color-secondary-contrast);
  --mbp-map-color-card-box-shadow: 0px 2px 48px #0000000A;
  --mbp-map-color-card-border: 1px solid var(--mbc-border-color);
  --mbp-map-color-card-border-radius: 12px;

  /** MBP Color Card variables **/
  --mbp-color-card-name-color: #282625;
  --mbp-color-card-background-hover-color: #f3f3f3;
  --mbp-color-card-icon-color: var(--mbc-default-node-color);
  --mbp-color-card-background: var(--ion-color-secondary-contrast);
  --mbp-color-card-box-shadow: 0px 2px 48px #0000000A;
  --mbp-color-card-border: 1px solid var(--mbc-border-color);
  --mbp-color-card-border-radius: 12px;

    /** Detailing colors variables **/
    --detailing-colors-tile-background: var(--ion-color-secondary-contrast);
    --detailing-colors-tile-box-shadow: 0px 2px 48px #0000000A;
    --detailing-colors-tile-border: 1px solid var(--mbc-border-color);
    --detailing-colors-tile-border-radius: 12px;

  /** Door Document Card Variables **/
  --door-document-card-progress-background: var(--mbc-primary) 0% 0% no-repeat padding-box;
  --door-document-card-detach-icon: #FF0000;
  --door-document-card-boarder: #EAEBEC;
  --door-document-card-download-icon: #0191FF;

    /** Detailing Card variables **/
    --detail-code-color-card-background-hover-color:#f3f3f3;
    --detail-code-color-card-icon-color : var(--mbc-default-node-color);
    --detail-code-color-card-background: var(--ion-color-secondary-contrast);
    --detail-code-color-card-box-shadow: 0px 2px 48px #0000000A;
    --detail-code-color-card-border: 1px solid var(--mbc-border-color);
    --detail-code-color-card-border-radius: 12px;

  /** Tab menu bar variables **/
  --tab-menu-bar-box-shadow: var(--floor-menu-bar-box-shadow);
  --tab-menu-bar-selected-background-color: var(--floor-menu-bar-selected-background-color);
  --tab-menu-bar-background: var(--mbc-secondary-background-color);
  --tab-menu-bar-unselected-text-color: var(--floor-menu-bar-unselected-text-color);
  --tab-menu-bar-selected-text-color: var(--floor-menu-bar-selected-text-color);

  /** Floor Plan Viewer Save Button **/
  --floor-plan-viewer-save-button-disabled-background: var(--floor-menu-bar-background) 0% 0% no-repeat padding-box;
  --floor-plan-viewer-save-button-disabled-text-color: var(--floor-menu-bar-unselected-text-color);
  --floor-plan-viewer-save-button-background: var(--floor-menu-bar-selected-background-color) 0% 0% no-repeat padding-box;
  --floor-plan-viewer-save-button-background-hover: #A8A3A1 0% 0% no-repeat padding-box;
  --floor-plan-viewer-save-button-text-color: var(--floor-menu-bar-selected-text-color);
  --floor-plan-viewer-save-button-box-shadow: var(--floor-menu-bar-box-shadow);

  /** Door node filter variables **/
  --door-node-filter-card-hover-color:#f3f3f3;
  --door-node-filter-name-color: #282625;
  --door-node-filter-card-left-box-background-color: #A8A3A1;
  --door-node-filter-card-background: var(--ion-color-secondary-contrast);
  --door-node-filter-card-box-shadow: 0px 2px 48px #0000000A;
  --door-node-filter-card-border: 1px solid var(--mbc-border-color);
  --door-node-filter-card-border-radius: 12px;
  --door-node-filter-card-checkbox-size: 16px;
  --door-node-filter-card-name-font-style: var(--mbc-font-style-default);
  --door-node-filter-card-name-font-variant: var(--mbc-font-variant-default);
  --door-node-filter-card-name-font-family: var(--mbc-font-type-light);

  //** Door node filter side panel **/
  --floor-plan-viewer-door-node-filter-panel-background: var(--floor-menu-bar-background);
  --floor-plan-viewer-door-node-filter-panel-unselected-text-color: var(--tab-menu-bar-unselected-text-color);
  --floor-plan-viewer-door-node-filter-panel-selected-background-color: var(--tab-menu-bar-selected-background-color);
  --floor-plan-viewer-door-node-filter-panel-box-shadow: var(--tab-menu-bar-box-shadow);
  --floor-plan-viewer-door-node-filter-panel-selected-text-color: var(--tab-menu-bar-selected-text-color);

  /** MBE Viewer variables **/
  --mbc-mbe-viewer-text-color-title: var(--map-colors-name-color);
  --mbc-mbe-viewer-text-color-subtitle: #7C7775;

    /** MBP Viewer variables **/
    --mbc-mbp-viewer-text-color-title: var(--map-colors-name-color);
    --mbc-mbp-viewer-text-color-subtitle: #7C7775;

      /** MBE Viewer variables **/
  --management-viewer-menu-title-color: var(--map-colors-name-color);
  --management-viewer-menu-subtitle-color: #7C7775;

   /** Tag Properties variables **/
  --mbe-tag-properties-name-color: #282625;
  --mbe-tag-properties-tile-background: var(--ion-color-secondary-contrast);
  --mbe-tag-properties-tile-box-shadow: 0px 2px 48px #0000000A;
  --mbe-tag-properties-tile-border: 1px solid var(--mbc-border-color);
  --mbe-tag-properties-tile-border-radius: 12px;

  /** Document Columns Setting variables **/
  --column-settings-name-color: #282625;
  --column-settings-tile-background: var(--ion-color-secondary-contrast);
  --column-settings-tile-box-shadow: 0px 2px 48px #0000000A;
  --column-settings-tile-border: 1px solid var(--mbc-border-color);
  --column-settings-tile-border-radius: 12px;

  /** Document Selected Header Field Card variables **/
  --selected-header-card-hover-color: #f3f3f3;
  --selected-header-card-background: var(--ion-color-secondary-contrast);
  --selected-header-card-box-shadow: 0px 2px 48px #0000000A;
  --selected-header-card-border: 1px solid var(--mbc-border-color);
  --selected-header-card-border-radius: 12px;
  --selected-header-card-name-color: #282625;

  /** Document Door Field Card variables **/
  --door-field-card-hover-color:#f3f3f3;
  --door-field-card-name-color: #282625;
  --door-field-card-background: var(--ion-color-secondary-contrast);
  --door-field-card-box-shadow: 0px 2px 48px #0000000A;
  --door-field-card-border: 1px solid var(--mbc-border-color);
  --door-field-card-border-radius: 12px;

  /** MBC Door Field Card variables **/
  --mbc-door-field-card-name-color: #282625;
  --mbc-door-field-card-hover-color:#f3f3f3;
  --mbc-door-field-card-background: var(--ion-color-secondary-contrast);
  --mbc-door-field-card-box-shadow: 0px 2px 48px #0000000A;
  --mbc-door-field-card-border: 1px solid var(--mbc-border-color);
  --mbc-door-field-card-border-radius: 12px;

   /** MBE Door Field Card variables **/
   --mbe-door-field-card-hover-color:#f3f3f3;
   --mbe-door-field-card-left-box-background-color: #A8A3A1;
   --mbe-door-field-card-background: var(--ion-color-secondary-contrast);
   --mbe-door-field-card-box-shadow: 0px 2px 48px #0000000A;
   --mbe-door-field-card-border: 1px solid var(--mbc-border-color);
   --mbe-door-field-card-border-radius: 12px;
  
  // ** MBE Right side panel variables **/
  --mbe-door-props-panel-scrollable-box-shadow: var(--mbc-tile-border-shadow);
  --mbe-door-props-panel-scrollable-background-color : var(--mbc-tile-scroll-background-color);
  --mbe-door-props-panel-scrollable-thumb-background-color: var(--mbc-tile-scroll-thumb-background-color);
  --mbe-door-props-panel-scrollable-thumb-border-color: var(--mbc-tile-scroll-thumb-border-color);
  --mbe-door-props-panel-scrollable-thumb-hover-color: var(--mbc-tile-scroll-thumb-hover-color);

   /** Tagged MBE Door Field Card variables **/
   --tagged-mbe-door-field-card-hover-color:#f3f3f3;
   --tagged-mbe-door-field-card-left-box-background-color: #A8A3A1;
   --tagged-mbe-door-field-card-background: var(--ion-color-secondary-contrast);
   --tagged-mbe-door-field-card-box-shadow: 0px 2px 48px #0000000A;
   --tagged-mbe-door-field-card-border: 1px solid var(--mbc-border-color);
   --tagged-mbe-door-field-card-border-radius: 12px;
  
  /** MBC Door Tag Card variables **/
  --mbc-door-tag-card-name-color: #282625;
  --mbc-door-tag-card-hover-color:#f3f3f3;
  --mbc-door-tag-card-background: var(--ion-color-secondary-contrast);
  --mbc-door-tag-card-box-shadow: 0px 2px 48px #0000000A;
  --mbc-door-tag-card-border: 1px solid var(--mbc-border-color);
  --mbc-door-tag-card-border-radius: 12px;

  /** Floow plan viewer settings panel **/
  --floor-plan-viewer-settings-panel-item-header-color: #585858;
  --floor-plan-viewer-readonly-settings-item-header-color: #585858;
  --floor-plan-viewer-settings-panel-zoom-tile-name-color: var(--mbc-text-color-light);
  --floor-plan-viewer-readonly-settings-zoom-tile-name-color: var(--mbc-text-color-light);

  /** mbe-project-setting-panel settings panel **/
  --mbe-project-settings-panel-item-header-color: #585858;
  --mbe-project-settings-panel-zoom-tile-name-color: var(--mbc-text-color-light);

   /** mbe-project-setting-readonly panel **/
  --mbe-project-settings-readonly-item-header-color: #585858;
  --mbe-project-settings-readonly-zoom-tile-name-color: var(--mbc-text-color-light);
  
  /** mbe-user-setting-panel settings panel **/
  --mbe-user-settings-panel-item-header-color: #585858;
  --mbe-user-settings-panel-zoom-tile-name-color: var(--mbc-text-color-light);
  --mbe-user-settings-panel-button-wrapper-background : var(--floor-menu-bar-background);
  --mbe-user-settings-panel-button-background: var(--floor-menu-bar-selected-background-color) 0% 0% no-repeat padding-box;
  --mbe-user-settings-panel-button-background-hover: #A8A3A1 0% 0% no-repeat padding-box;
  --mbe-user-settings-panel-button-text-color: var(--floor-menu-bar-selected-text-color);
  --mbe-user-settings-panel-button-box-shadow: var(--floor-menu-bar-box-shadow);
  --mbe-user-settings-panel-button-disabled-background: var(--floor-menu-bar-background) 0% 0% no-repeat padding-box;
  --mbe-user-settings-panel-button-disabled-text-color: var(--floor-menu-bar-unselected-text-color);

  /** mbp-user-setting-panel settings panel **/
  --mbp-user-settings-panel-item-header-color: #585858;
  --mbp-user-settings-panel-zoom-tile-name-color: var(--mbc-text-color-light);
  --mbp-user-settings-panel-button-wrapper-background : var(--floor-menu-bar-background);
  --mbp-user-settings-panel-button-background: var(--floor-menu-bar-selected-background-color) 0% 0% no-repeat padding-box;
  --mbp-user-settings-panel-button-background-hover: #A8A3A1 0% 0% no-repeat padding-box;
  --mbp-user-settings-panel-button-text-color: var(--floor-menu-bar-selected-text-color);
  --mbp-user-settings-panel-button-box-shadow: var(--floor-menu-bar-box-shadow);
  --mbp-user-settings-panel-button-disabled-background: var(--floor-menu-bar-background) 0% 0% no-repeat padding-box;
  --mbp-user-settings-panel-button-disabled-text-color: var(--floor-menu-bar-unselected-text-color);

  /** MBP Select company card variables **/
  --mbp-select-company-card-background: var(--mbc-card-background);
  --mbp-select-company-card-wrapper-border: var(--mbc-card-border-color);
  --mbp-select-company-card-wrapper-box-shadow: var(--mbc-card-border-shadow);
  --mbp-select-company-card-left-box-background: var(--mbc-card-selector-color);
  --mbp-select-company-card-select-button-color: var(--mbc-button-primary-color);
  --mbp-select-company-card-companyName-color: var(--mbc-card-header-text-color);
  --mbp-select-company-card-companyName-color-font-style: var(--mbc-font-style-default);
  --mbp-select-company-card-companyName-color-font-variant: var(--mbc-font-variant-default);
  --mbp-select-company-card-companyName-color-font-weight: var(--mbc-font-weight-default);
  --mbp-select-company-card-companyName-color-font-family: var(--mbc-font-type-default);
  --mbp-select-company-card-tradeName-color: var(--mbc-card-label-light);

  // ** MBP Right side panel variables **/
  --mbp-door-props-panel-scrollable-box-shadow: var(--mbc-tile-border-shadow);
  --mbp-door-props-panel-scrollable-background-color : var(--mbc-tile-scroll-background-color);
  --mbp-door-props-panel-scrollable-thumb-background-color: var(--mbc-tile-scroll-thumb-background-color);
  --mbp-door-props-panel-scrollable-thumb-border-color: var(--mbc-tile-scroll-thumb-border-color);
  --mbp-door-props-panel-scrollable-thumb-hover-color: var(--mbc-tile-scroll-thumb-hover-color);
  --mbp-door-props-panel-message-header-color: #282625;
  --mbp-door-props-panel-message-arrow-icon-color: #c2c2c2;

  // ** MBP Filters panel variables **/
  --mbp-filters-panel-button-background-color: var(--mbc-secondary-background-color);
  --mbp-filters-panel-button-selected-background-color: var(--ion-color-primary-contrast);
  --mbp-filters-panel-button-selected-box-shadow: var(--floor-menu-bar-box-shadow);
  --mbp-filters-panel-button-selected-text-color: var(--ion-color-primary);
  --mbp-filters-panel-button-disabled-text-color: var(--floor-menu-bar-unselected-text-color);
  --mbp-filters-panel-scrollable-thumb-background-color: var(--mbc-tile-scroll-thumb-background-color);
  --mbp-filters-panel-scrollable-thumb-border-color: var(--mbc-tile-scroll-thumb-border-color);
  --mbp-filters-panel-scrollable-thumb-hover-color: var(--mbc-tile-scroll-thumb-hover-color);

  // ** MBP Filters item card variables **/
  --mbp-filter-item-card-background: var(--ion-color-secondary-contrast);
  --mbp-filter-item-card-border: 1px solid var(--mbc-border-color);
  --mbp-filter-item-card-border-radius: 12px;
  --mbp-filter-item-card-left-box-background: var(--mbc-default-node-color);
  --mbp-filter-item-card-name-color: #282625;
  --mbp-filter-item-card-hover-color: #f3f3f3;

  // ** MBE Viewer menu variables
  --mbe-viewer-menu-button-color: #A8A3A1;

  // ** MBP Viewer menu variables
  --mpb-viewer-menu-button-color: #A8A3A1;

  // ** MBC Viewer menu variables
  --mbc-viewer-menu-button-color: #A8A3A1;

  // ** Document menu variables
  --document-menu-icon-color: #A8A3A1;


  /** MBE Filter Panel variables **/
  
  --mbe-filters-panel-button-wrapper-background : var(--floor-menu-bar-background);
  --mbe-filters-panel-item-wrapper-scrollable-scrollbar-thumb-background: #e6e5e5;
  --mbe-filters-panel-item-wrapper-scrollable-scrollbar-thumb-hover-color: #d7d6d6;
  --mbe-filters-panel-item-wrapper-scrollable-scrollbar-thumb-border-color: var(--mbc-border-color);

   /** MBE Filters item card variables **/
   --mbe-filter-item-card-background: var(--ion-color-secondary-contrast);
   --mbe-filter-item-card-border: 1px solid var(--mbc-border-color);
   --mbe-filter-item-card-border-radius: 12px;
   --mbe-filter-item-card-left-box-background: var(--mbc-default-node-color);
   --mbe-filter-item-card-name-color: #282625;
   --mbe-filter-item-card-hover-color: #f3f3f3;

   /** MBC Main Menu variables **/
   --mbc-main-menu-header-color: #303849; // #FFFFFF;
   --mbc-main-menu-seperator-color: #bfbfbf; //#525252;
   --mbc-main-menu-item-color: #303849; //#d4d4d4;
   --mbc-main-menu-background-color: #dedede; // #303849;

  /** MBP Mount Checklist variables **/
  --mbp-mount-checklist-card-wrapper-scrollbar-background-color: #9898980a;
  --mbp-mount-checklist-card-wrapper-scrollbar-thumb-background-color: #e6e5e5;
  --mbp-mount-checklist-card-wrapper-scrollbar-thumb-hover-color: #d7d6d6;
  --mbp-mount-checklist-card-wrapper-scrollbar-thumb-border-color: var(--mbc-border-color);

  /** MBP Mount Checklist Card variables **/
  --mbp-mount-checklist-card-name-color: #282625;
  --mbp-mount-checklist-card-background-hover-color: #f3f3f3;
  --mbp-mount-checklist-card-background: var(--ion-color-secondary-contrast);
  --mbp-mount-checklist-card-border: 1px solid var(--mbc-border-color);
  --mbp-mount-checklist-card-border-radius: 12px;

  /** MBC Checklist Card variables **/
  --mbc-checklist-card-name-color: #282625;
  --mbc-checklist-card-background-hover-color: #f3f3f3;
  --mbc-checklist-card-background: var(--ion-color-secondary-contrast);
  --mbc-checklist-card-border: 1px solid var(--mbc-border-color);
  --mbc-checklist-card-border-radius: 12px;

  /** MBP Issue Message variables **/
  --mbp-issue-message-right-border: 1px solid var(--mbc-border-color);
  --mbp-issue-message-right-border-radius: 12px;
  --mbp-issue-message-right-background: #eaebec;
  --mbp-issue-message-card-box-shadow: 0px 2px 48px #0000000A;
  --mbp-issue-message-card-content-info-color: #202020;
  --mbp-issue-message-card-background-color: #FFFFFF;

  /** MBC Door Note Image Card **/
  --door-note-image-card-content-background: #eaebec;
  --door-note-image-card-content-border: 1px solid var(--mbc-border-color);
  --door-note-image-card-content-radius: 12px;
  
  /** MBC Door Note Panel **/
  --door-notes-panel-header-color: #585858;
  --door-notes-panel-attach-image-wrapper-background: #eaebec;
  --door-notes-panel-attach-image-wrapper-border: 1px solid var(--mbc-border-color);
  --door-notes-panel-attach-image-wrapper-radius: 12px;
  
  /** Ionic Checkbox specific variables **/
  --ion-checkbox-border-color: 1px solid #cfcfcf;
  --ion-checkbox-background: var(--ion-color-primary-contrast);
  --ion-checkbox-background-checked: var(--ion-color-primary-contrast);
  --ion-checkbox-check-color: var(--mbc-text-color-default);

  /** MBC right panel variables **/

  --mbc-right-panel-secondary-panel-background: var(--mbc-secondary-background-color);

  /** MBC filter card variables **/     
  --mbc-filter-card-background: var(--ion-color-secondary-contrast);
  --mbc-filter-card-border: 1px solid var(--mbc-border-color);
  --mbc-filter-card-border-radius: 12px;
  --mbc-filter-card-left-box-background: var(--mbc-default-node-color);
  --mbc-filter-card-text-color: #282625;
  --mbc-filter-card-hover-color: #f3f3f3;

  /** MBC Viewer Ipad Left Panel variables **/
  --viewer-ipad-left-panel-item-border-bottom: solid 1px #DDDDDD;
  --viewer-ipad-left-panel-item-icon-color: var(--mbc-primary);
  --viewer-ipad-left-panel-item-name-color: #7C7775;

  /** Ionic specific variables **/
  --ion-background-color: #FAFAFA;

  --ion-color-primary: var(--mbc-primary);
	--ion-color-primary-rgb: 1,145,255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0180e0;
	--ion-color-primary-tint: #1a9cff;

	--ion-color-secondary: var(--mbc-secondary);
	--ion-color-secondary-rgb: 249,121,73;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #db6a40;
	--ion-color-secondary-tint: #fa865b;

	--ion-color-tertiary: #F7DF86;
	--ion-color-tertiary-rgb: 247,223,134;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #d9c476;
	--ion-color-tertiary-tint: #f8e292;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #615c5a;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
