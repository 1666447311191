ion-checkbox.mbc-checkbox-default {
    --checkbox-background-checked: var(--ion-checkbox-background-checked);
    --checkbox-background: var(--ion-checkbox-background);
    --checkmark-color: var(--ion-checkbox-check-color);
}

ion-checkbox.mbc-checkbox-default::part(container) {
    border-radius: 0px;
    border: var(--ion-checkbox-border-color);
}

.mbc-checkbox-default {
    --size: var(--mbc-checkbox-size);
    --checkbox-background-checked: var(--ion-color-primary-contrast);
    --checkbox-background: var(--ion-color-primary-contrast);
    --checkmark-color: var(--mbc-text-color-default);
}
  
.mbc-checkbox-default::part(container) {
    border-radius: 0px;
    border: var(--ion-checkbox-border-color);
}