  .mbc-menu{
    display: flex;
    background: #FAFAFA;
    padding: 20px 10px 0px 10px;
  }  

  .mbc-menu-right{
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  
  .mbc-menu-button{
    width: unset;
    color: var(--mbc-menu-button-color);
    font-size: 30px;  
    --background-hover: unset;
    margin-right: 5px;
  }

  .mbc-menu-header{
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }

  .mbc-menu-title{
    color: #111111;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 26px;
    font-family: "Roboto";
    letter-spacing: 1.4px;
    text-shadow: 0px 0px 1px #747474;
    
  }

  .mbc-menu-subtitle{
    color:#b8b8b8 ;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
    font-family: "Roboto";
    letter-spacing: 1.3px;
    text-shadow: 0px 0px 1px #afafaf;
  }