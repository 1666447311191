.main-container {
  display: flex;
  flex-direction: row;
  text-align: center;    
  padding-top: 2px;
  height: 100%;
  gap: 10px;
}
  
.main-container-left {
  width: 422px;    
  display:flex;
  flex-direction: column;
  gap: 10px;
}
  
.main-container-center {
  padding: 0px 10px;
  width: 100%;
  height: 100%;    
  display:flex;
  flex-direction: column;
  flex: 1;
}

.main-container-right {
  width: 432px;
}

.page-header{    
  display: flex;
  background: var(--ion-background-color);
}

.page-header-title-container{
  text-align: center;
  flex-grow: 1;
}

.page-header-title{
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-weight: var(--mbc-font-weight-default);
  font-family: var(--mbc-font-type-medium);
  color: var(--mbc-text-color-default);
  font-size: 20px;
  letter-spacing: 0.4px;
}

.page-header-subtitle{
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-weight: var(--mbc-font-weight-default);
  font-family: var(--mbc-font-type-medium);
  font-size: 11px;
  color: var(--mbc-text-color-light);
}

.page-button-panel-bottom{    
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.mbc-ion-content-unscrollable{
  --overflow: hidden;
}

.mbc-container-row{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.mbc-container-left-content {
  overflow-x: hidden;
  overflow-y: auto;
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: var(--mbc-tile-border-shadow);
}