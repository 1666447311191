.mbc-card-menu{
    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-family: var(--mbc-font-type-medium);
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
    color: var(--ion-color-medium);
    opacity: 1;
    margin: 12px;
}

.mbc-card-menu-item{
    margin-top: 9px;
    padding-bottom: 10px;
    opacity: 1;
    cursor: pointer;
}

.mbc-card-menu-item-default{
    @extend .mbc-card-menu-item;
    border-bottom: var(--mbc-text-underline-border);
}

.mbc-card-menu-item-important{
    @extend .mbc-card-menu-item;
    color: var(--mbc-text-color-important);
}


.mbc-card-selected {
    background-color: var(--mbc-card-selected-color) !important;
}