.mbc-scrollable {  
       
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: var(--mbc-tile-scroll-background-color);
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--mbc-scrollable-thumb-background-color);
        border: 1px solid var(--mbc-scrollable-thumb-border-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(---mbc-scrollable-thumb-hover-color);
    }
}

.mbc-scrollable-thin {  
    &::-webkit-scrollbar {
        width: 4px;
        background: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--mbc-tile-scroll-thumb-background-color);
        border: 1px solid var(--mbc-tile-scroll-thumb-border-color);
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--mbc-tile-scroll-thumb-hover-color);
    }
}