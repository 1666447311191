.mbc-dropdown{
  display: flex;
  background: none;
}

.mbc-dropdown ion-select{
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-weight: var(--mbc-font-weight-default);
  font-family: var(--mbc-font-type-default);
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.6px;

  width: 100%;
  min-height: 45px;

  background: var(--mbc-main-background);
  border: 1px solid var(--mbc-border-color);
  border-radius: 12px;
  --highlight-color: var(--ion-color-primary);

  opacity: 1;
  padding-left:35px;
  padding-right:15px;
}

.mbc-dropdown ion-select::part(icon) {
  color: transparent;
  transform: rotate(-90deg);
  content: url('/assets/icon/arrow-blue-left.png');
  width: 20px;
  height: 20px;
  margin-top: 13px;
}

.mbc-dropdown ion-select.select-expanded::part(icon) {
  transform: rotate(90deg);
}

.mbc-dropdown ion-select.floor-plan-viewer-node-template-select{
  padding-left:15px;
  overflow: hidden;
}

.mbc-dropdown ion-select.floor-plan-viewer-readonly-node-template-select{
  padding-left:15px;
  overflow: hidden;
}

.mbc-dropdown ion-select.mbe-project-settings-panel-node-template-select{
  padding-left:15px;
  overflow: hidden;
}

.mbc-dropdown ion-select.mbe-project-settings-readonly-node-template-select{
  padding-left:15px;
  overflow: hidden;
}

.mbc-dropdown ion-select.mbm-workorders-select {
  min-height: 48px;
}

.map-colors-select-popover ion-select-popover {
  max-height: 410px;
}

.mbc-dropdown ion-select.mbm-project-settings-panel-node-template-select{
  padding-left:15px;
  overflow: hidden;
}