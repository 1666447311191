.mbc-loading-screen {
    --background: transparent;
    --backdrop-opacity: 0.5;
    --spinner-color: var(--mbc-loading-screen-spinner-color);
  
    .loading-wrapper {
      padding: 0px;
      border: none;
      box-shadow: none;
    }
  
    ion-spinner {
      transform: scale(2);
      opacity: 0.5;
    }
  }