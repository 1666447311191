.mbc-main-menu-header{
    align-items: center;
    display: flex;
    flex-direction: column;
    color: var(--mbc-main-menu-header-color);
  }
  
  .mbc-main-menu-items-wrapper{
  margin-left:20px;
  margin-top:10px;
  }
  
  .mbc-main-menu-seperator{
    height: 1px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: var(--mbc-main-menu-seperator-color);
    margin-bottom: 10px;
    margin-top:10px;
  }
  
  .mbc-main-menu-item{
    margin-top:13px;
   font-size: 14px;
   color: var(--mbc-main-menu-item-color); 
   cursor: pointer;
  }
  
  ion-menu ion-content {
    --background: var(--mbc-main-menu-background-color);
  }

.mbc-menu-side{
  min-width: 100px;
  align-self: center;
}