/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/layout.scss";
@import "theme/mbc-menu.scss";
@import "theme/mbc-notification.scss";
@import "theme/mbc-main-tile.scss";
@import "theme/mbc-search.scss";
@import "theme/mbc-card-menu.scss";
@import "theme/mbc-button.scss";
@import "theme/mbc-loading-screen.scss";
@import "theme/mbc-main-menu.scss";
@import "theme/mbc-dropdown.scss";
@import "theme/mbc-input.scss";
@import "theme/mbc-checkbox.scss";
@import "theme/mbc-scroll.scss";
/* Core Data Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

@font-face {
  font-family: 'Roboto';
  src: url('/assets/font/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('/assets/font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-light';
  src: url('/assets/font/Roboto-Light.ttf');
}

ion-modal.mbc-modal {
  --width:1037px;
  --height: 600px;
  --max-height: 100vh;
}

ion-modal.connect-project-modal {
  @extend .mbc-modal;
}

ion-modal.map-colors-modal {
  @extend .mbc-modal;
}

ion-modal.mbp-map-colors-modal {
  @extend .mbc-modal;
}

ion-modal.detailing-colors-modal {
  @extend .mbc-modal;
}

ion-modal.detailing-code-update-modal {
  @extend .mbc-modal;
  --width: 500px;
  --height: 400px;
}

ion-modal.detailing-code-update-modal ion-content{
  contain: content;
}

ion-modal.mbe-tag-properties-modal {
  @extend .mbc-modal;
}

ion-modal.mbm-tags-modal {
  @extend .mbc-modal;
}

ion-modal.mbp-tags-modal {
  @extend .mbc-modal;
}

ion-modal.document-column-settings-modal {
  @extend .mbc-modal;
}

ion-modal.floor-plan-files-confirm-modal{
  @extend .mbc-modal;
}

ion-modal.company-select-modal {
  @extend .mbc-modal;
}

ion-modal.mbc-confirmation-modal {
  --width:500px;
  --height: auto;
}

ion-modal.mbc-confirmation-modal ion-content{
  contain: content;
}

ion-modal.mbc-confirmation-modal ion-content::part(scroll) {
  position: relative;
}

ion-modal.floor-plan-viewer-confirm-modal {
  --width: 778px;
}

ion-modal.upload-document-confirm-modal {
  --width: 700px;
}

ion-modal.detach-document-confirm-modal {
  --width: 500px;
}

ion-modal.mbc-error-message-modal {
  --width:500px;
  --height: auto;
}

ion-modal.mbc-error-message-modal ion-content{
  contain: content;
}

ion-modal.mbc-error-message-modal ion-content::part(scroll) {
  position: relative;
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.25);
  --box-shadow: none;
}

ion-modal.mbp-mount-checklist-modal {
  --width: 450px;
  --height: 400px;
}

ion-modal.mbc-checklist-modal {
  @extend .mbc-modal;
  --width: 550px;
  --height: 400px;
}

ion-modal.mbp-issue-message-modal {
  @extend .mbc-modal;
  --width: 900px;
  --height: 510px;
}

ion-modal.mbm-workorder-templates-modal {
  @extend .mbc-modal;
  --width: 470px;
  --height: 144px;
}

ion-modal.mbm-message-modal {
  @extend .mbc-modal;
  --width: 900px;
  --height: 510px;
}

.mbc-confirm-box {
  --width: 400px;

  .alert-wrapper {
    max-width: 400px;
    .alert-head {
      font: var(--mbc-font-type-default);
    }

  }
}

.mbc-input-alert{
  .alert-head .alert-title {
      color: var(--mbc-tile-header-color);
      font-style: var(--mbc-font-style-default);
      font-variant: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 26px;
      font-family: "Roboto";
      letter-spacing: 1.4px;
      text-shadow: 0px 0px 1px --var(--mbc-text-shadow-default);
    }
}

ion-checkbox.mbe-door-field-card-checkbox {
  --size: var(--mbc-checkbox-size);
  --checkbox-background-checked: var(--ion-color-primary-contrast);
  --checkbox-background: var(--ion-color-primary-contrast);
  --checkmark-color: var(--mbc-text-color-default);
}

ion-checkbox.mbe-door-field-card-checkbox::part(container) {
  border-radius: 0px;
  border: var(--ion-checkbox-border-color);
}

ion-checkbox.door-field-card-checkbox {
  --size: var(--mbc-checkbox-size);
  --checkbox-background-checked: var(--ion-color-primary-contrast);
  --checkbox-background: var(--ion-color-primary-contrast);
  --checkmark-color: var(--mbc-text-color-default);
}

ion-checkbox.door-field-card-checkbox::part(container) {
  border-radius: 0px;
  border: var(--ion-checkbox-border-color);
}

.mbc-back-icon{
  min-width: 35px;
  height: 28px;
  cursor: pointer;
  color: var(--mbc-back-icon-color);
  background-color: none;
}

.mbc-back-icon:hover{
  color: var(--mbc-back-icon-hover-color);
}

.mbc-back-icon:active{
  color: var(--mbc-back-icon-color);
}

.reorder-selected{
  box-shadow: none;
}

.mbc-camera-icon{
  min-width: 35px;
  height: 28px;
  cursor: pointer;
  color: var(--mbc-back-icon-color);
  background-color: none;
}

.mbc-camera-icon:hover{
  color: var(--mbc-back-icon-hover-color);
}

.mbc-camera-icon:active{
  color: var(--mbc-back-icon-color);
}

.mbc-viewer-menu-button {
  background: none;
}

.document-menu-button{
  background: none;
}

.document-card-button{
  background: none;
}

.door-document-card-detach-button{
  background: none;
}

.document-page-data-cells {
  text-align: left;
  color: var(--mbc-text-color-default);
  letter-spacing: 1px;
  font-family: var(--mbc-font-type-medium);
  font-weight: var(--mbc-font-weight-default);
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-size: 14px;
}

.ag-theme-quartz .ag-header-cell {
  font-family: var(--mbc-font-type-default);
  font-weight: var(--mbc-font-weight-default);
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-size: 15px;
}

ion-button[disabled].mbc-disable-button-primary {
  --cursor: default;
  --opacity: .4;
  --pointer-events: none;
}
