ion-input.mbc-input {
    font-family: var(--mbc-font-type-light);
    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-size: 15px;
    letter-spacing: 0.93px;
    background: var(--mbc-input-background);
    border: var(--mbc-input-border);
    border-radius: 12px;
    --placeholder-color: var(--mbc-input-placeholder-color);
    --highlight-color-focused: none;
    --padding-start: 20px;
    --padding-end: 20px;
    min-height: 48px !important;
    opacity: 1;
    width: 100%;
    text-align: left;
}

ion-textarea.mbc-textarea {
    font-family: var(--mbc-font-type-light);
    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-size: 15px;
    letter-spacing: 0.93px;
    background: var(--mbc-input-background);
    border: var(--mbc-input-border);
    border-radius: 12px;
    --placeholder-color: var(--mbc-input-placeholder-color);
    --highlight-color-focused: none;
    --highlight-color-valid: none;
    --padding-start: 20px;
    --padding-end: 20px;
    min-height: 40px;
    opacity: 1;
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: auto;
}

ion-textarea.mbc-textarea-scrollable {
    @extend .mbc-textarea;
    @extend .mbc-scrollable-thin; 
}

ion-input.mbc-inner-input {
    font-family: var(--mbc-font-type-default);
    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-size: 12px;
    letter-spacing: 0.93px;
    background: var(--mbc-input-background);
    border: 1px solid var(--mbc-primary);
    border-radius: 10px;
    --placeholder-color: var(--mbc-input-placeholder-color);
    --highlight-color-valid: none;
    --padding-start: 20px;
    --padding-end: 20px;
    min-height: 30px !important;
    opacity: 1;
    width: 100%;
    text-align: right;
    text-decoration: none;
}