.mbc-main-tile {
    display: flex;
    flex-direction: column;
    background: var(--mbc-main-background);
    box-shadow: var(--mbc-tile-border-shadow);
    border: 1px solid var(--mbc-border-color);
    border-radius: var(--mbc-tile-border-radius);
    opacity: 1;
    padding: 15px 35px;
    overflow: hidden;
}

.mbc-main-tile-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: var(--mbc-text-underline-border);
    margin-bottom: 20px;
}

.mbc-main-tile-header-name {
    display: flex;
    color: var(--mbc-tile-header-color);
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 19px;
    line-height: 40px;
    font-family: "Roboto-light";
    letter-spacing: 0.7px;
    opacity: 1;
}

.mbc-main-tile-header-info {
  font-style: var(--mbc-font-style-default);
  font-variant: var(--mbc-font-variant-default);
  font-weight: var(--mbc-font-weight-default);
  font-family: var(--mbc-font-type-default);
  font-size: 9px;
  display:flex;
  color:var(--mbc-tile-header-info-color);
  opacity: 1;
  align-items: flex-end;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.mbc-main-tile-content-full {
  display: flex;
  width: 100%;
  opacity: 1;
  flex-direction: column;
  box-shadow: var(--mbc-tile-border-shadow);
  overflow: auto;
}

.mbc-main-tile-content {
    display: flex;
    width: 100%;
    opacity: 1;
    flex-direction: column;
    box-shadow: var(--mbc-tile-border-shadow);
    overflow: auto;

    @extend .mbc-scrollable;
}

ion-content.mbc-main-tile-content::part(scroll) {
  scrollbar-width: thin;
  scrollbar-color: var(--mbc-tile-scroll-thumb-background-color) var(--mbc-tile-scroll-background-color);
}