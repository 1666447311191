ion-button.mbc-button-primary {

    display: flex;
    justify-content: center;
    --background: var(--mbc-button-primary-color);

    --background-hover: var(--mbc-button-primary-hover-color);
    --background-activated: var(--mbc-button-primary-activated-color);
    --background-focused: var(--mbc-button-primary-focused-color);
    --border-color: var(--mbc-button-primary-border-color);
    --ripple-color: var(--mbc-button-primary-ripple-color);

    --color: var(--mbc-button-primary-text-color);
    --border-radius: 15px;
    --border-style: solid;
    --border-width: 1px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-end: 50px;
    --padding-start: 50px;

    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-family: var(--mbc-font-type-light);
    font-size: 13px;
    line-height: 26px;
    text-transform: none;
    opacity: 1;
    min-height: 33px;

    //this width will be change according to the text inside the button.
    min-width: 135px;
    
}

ion-button.mbc-button-primary-small-padding {
    @extend .mbc-button-primary;
    --padding-start: 20px;
    --padding-end: 20px;
}

ion-button.mbc-button-primary.disabled {
    opacity: 0.5;
    pointer-events: none;
    --background: var(--mbc-button-primary-disabled-color);
    --border-color: var(--mbc-button-primary-disabled-color);
}

ion-button.mbc-button-primary.button-disabled{
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
}
  

ion-button.mbc-button-round-primary{
    width: 60px;
    height: 60px;
    --border-radius: 30px;
    --background: var(--mbc-button-primary-color);
    --color: var(--mbc-button-primary-text-color);
    
    ion-icon{
        font-size: 45px;
    }
}

ion-button.mbc-button-round-primary-small{
    @extend .mbc-button-round-primary;
    width: 50px;
    height: 50px;
}

ion-button.mbc-button-clear-primary{
    text-transform: none;
    width: 100%;
}

ion-button.mbc-button-danger {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    font-family: "Roboto";
    font-size: 15px;
    letter-spacing: 0.26px;
    line-height: 50px;
    --border-radius: 12px;
    --background: var(--mbc-danger);
    --color: var(--ion-color-secondary-contrast);
    --box-shadow: 0 transparent;
    text-transform: none;
    width: 100%;
    height: 42px;
}

ion-button.mbc-button-secondary{
    display: flex;
    justify-content: center;
    --background: var(--mbc-button-secondary-background);
    --background-hover: var(--mbc-button-secondary-background-hover);
    --color: var(--mbc-button-secondary-text-color);
    --border-radius: 12px;
    --border-width: 0px;
    --box-shadow: var(--mbc-button-secondary-box-shadow);

    font-style: var(--mbc-font-style-default);
    font-variant: var(--mbc-font-variant-default);
    font-weight: var(--mbc-font-weight-default);
    font-family: var(--mbc-font-type-medium);
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: none;
    cursor: pointer;
    text-wrap: nowrap;
    opacity: 1;
    width: 100%;
}

ion-button.mbc-button-secondary.button-disabled{
    --background: var(--mbc-button-secondary-disabled-background);
    color: var(--mbc-button-secondary-disabled-text-color);
    --box-shadow: none;
}