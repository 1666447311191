.mbc-notification-success::part(container) {
    background-color: var(--mbc-green);
    font: var(--mbc-notification-font);
}

.mbc-notification-warning::part(container) {
    background-color: var(--mbc-tertiary);
    font: var(--mbc-notification-font);
    color: var(--mbc-text-color-default);
}

.mbc-notification-error::part(container) {
    background-color: var(--ion-color-danger);
    font: var(--mbc-notification-font);
}